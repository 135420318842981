import { ref } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import useSystemCodes from "@/views/system-settings/system-codes/useSystemCodes";

// Use toast
const toast = useToast();

const refDevicesListTable = ref(null);

export default function useDevicesList() {
  // Table Handlers
  const tableColumns = [
    { key: "images", sortable: false },
    { key: "price", sortable: true },
    { key: "description", sortable: true },
    { key: "serial_no", sortable: true, label: "Serial No" },
    { key: "sku", sortable: false },
  ];

  const catalogueTableColumns = tableColumns.slice(1);
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  // Call System Codes
  useSystemCodes();
  const fetchDevices = (ctx, callback) => {
    store
      .dispatch("app-device/fetchDevices")
      .then((response) => {
        const records = response.data.data;

        callback(records);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Devices list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchDevices,
    tableColumns,
    catalogueTableColumns,
    sortBy,
    isSortDirDesc,
    refDevicesListTable
  };
}
