<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
                <!-- :to="{ name: 'general-settings-deviceMarketPlace-create' }" -->
        <b-row>
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'general-settings-deviceMarketPlace-create' }"
              >
                <span class="text-nowrap">Add Device</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDevicesListTable"
        class="position-relative"
        :items="fetchDevices"
        responsive
        :fields="
          $route.name === 'general-settings-deviceMarketPlace-list'
            ? tableColumns
            : catalogueTableColumns
        "
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Images -->
        <template #cell(images)="data">
          <img
            :src="data.item.images[0]"
            class="images"
          />
        </template>

        <!-- Column: price -->
        <template #cell(price)="data">
          {{ data.item.price }}
        </template>

        <!-- Column: description -->
        <template #cell(description)="data">
          <span>{{ data.item.description }}</span>
        </template>

        <!-- Column: serial_no -->
        <template #cell(serial_no)="data">
          {{ data.item.serial_no }}
        </template>

        <!-- Column: sku -->
        <template #cell(sku)="data">
          {{ data.item.sku }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import useDevicesList from "./useMarketPlaceList";
import marketPlaceStore from "../marketPlaceStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  directives: {
    Ripple,
  },
  setup() {
    const DEVICE_APP_STORE_MODULE_NAME = "app-device";

    // Register module
    if (!store.hasModule(DEVICE_APP_STORE_MODULE_NAME))
      store.registerModule(DEVICE_APP_STORE_MODULE_NAME, marketPlaceStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEVICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(DEVICE_APP_STORE_MODULE_NAME);
    });

    const {
      fetchDevices,
      tableColumns,
      catalogueTableColumns,
      sortBy,
      isSortDirDesc,
      refDevicesListTable,
    } = useDevicesList();

    return {
      fetchDevices,
      tableColumns,
      catalogueTableColumns,
      sortBy,
      isSortDirDesc,
      refDevicesListTable,
    };
  },
};
</script>

<style lang="scss" scoped>
.desp-width {
  width: 200px;
}
.images {
  max-width: 70px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
